import _ from 'lodash';
import {
  getBackendnameHashtags,
  replaceHashtagsByDependencies,
  replacer, getRegExpTpl,
  passStateToHashtagsUtils
} from '../../utils/hashtags';

import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';

import {
  buildScreen, generateLooping,
  loopComponent, findIndexOfComponentWith,
  passStateToScreenUtils, collectDependencies
} from '../../utils/screen';

export var gPromises = [];
export var gPromisesUUid = [];

function sleep6000(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < (i+1); i++) {
    let now1 = new Date().getTime();
    if ((now1 - start) > (milliseconds)){
      return 1;
    }
  }
}


export default {

  /**
   * получение экрана из кэша
   * @param dispatch {vuex context}
   * @param commit {vuex context}
   * @param rootState {vuex context}
   * @param id - screen ID
   * @returns {Promise<void>}
   */
  async getScreenFromCache({ dispatch, commit, rootState }, id) {
    let headers = {};
    if (rootState.screens && rootState.screens.data) {
      let cacheScreen = rootState.screens.data.find(screen => id == screen.id)

      if ( (cacheScreen.attributes || {}).LastModified ) {
        let index = {
          id: cacheScreen.id,
          ...cacheScreen.attributes
        };
        delete index.Name;
        index.name = 'mbst-screen';
        console.time('Set Cache Screen')

        // ** indexing all components for fast searching in future by UUID
        commit('createScreenIndex', index);

        await dispatch('setScreen', {screen: index});

        console.timeEnd('Set Cache Screen')

        // const format = 'ddd, DD MMM YYYY HH:mm:ss';
        const format = 'EEE, dd MMM yyyy HH:mm:ss';
        // const date = `${moment.parseZone(cacheScreen.attributes.LastModified).format(format)} GMT`
        const date = `${formatDate(parseISO(cacheScreen.attributes.LastModified), format)} GMT`
        headers['If-Modified-Since'] = date;
      }
    }

    // получим свежую версию экрана если есть
    dispatch('getScreenFromAPI', { headers, id });
    return Promise.resolve();
  },

  /**
   * получение экрана по АПИ
   * @param dispatch {vuex context}
   * @param commit {vuex context}
   * @param headers - заголовки, если есть параметр If-Modified-Since, ответит 200||304, иначе 200
   * @param id - screen ID
   * @returns {Promise<void>}
   */
  async getScreenFromAPI({ dispatch, commit }, { headers, id }) {
    const { screenid, appid: applicationId } = this.app.context.query;
    let screen = null;
    try {

      const res = await this.$axios.$get(`screen/${id}`, {
        headers,
        params: {
          id,
          applicationId,
          uni: Date.now() // TODO: Iphone Safari cache
        }
      });

      if ( !((res || {}).data || {}).attributes ) {
        throw {
          errors: [
            {
              title: 'Error',
              detail: '[screen] API method failed',
            }
          ]
        };
      }

      screen = {
        id: res.data.id,
        ...res.data.attributes
      };
      screen.name = 'mbst-screen';

      // indexing all components for fast searching in future by UUID
      commit('createScreenIndex', screen);

      // обновим экран среди кэшированных
      commit('updateScreenById', { id, screen }, { root: true });

      if (screenid == id) {
        await dispatch('setScreen', { screen });
        commit('setLastUpdatedFromAPI', Date.now())
      }

    } catch(e) {
      console.log(' setIsPreload caller 116 getScreenFromAPI');
      commit('setIsPreload', false);
      //commit('setIsPreload', false);
      const { status } = e.response || {}
      // if (status != 403 && status != 401) {
      if ([304, 403, 401].includes(status) === false) {
        const { errors } = (e.response || {}).data || e;
        if (errors) {
          if (errors[0].status == '400') {
            return Promise.reject(errors[0]);
          } else {
            return this.app.context.error({
              statusCode: 500, message: 'Can\'t launch application. Wrong Screen ID'
            });
          }
        }
      }
    }
  },

  /**
   * TODO: Установка экрана, разобраться, много лишних телодвижений
   * @param commit {vuex context}
   * @param rootState {vuex context}
   * @param dispatch {vuex context}
   * @param payload
   * @returns {Promise<*>}
   */
  async setScreen({ state, commit, getters, rootState, dispatch }, payload) {
    if (!payload.screen) {
      return this.app.context.error({ statusCode: 404 });
    }
    // console.log(' setIsPreload    window.showloopimages = null');
    // window.showloopimages = null;
    let screen = _.cloneDeep(payload.screen);
    delete screen.properties.prefetchImages;

    const { index } = findIndexOfComponentWith(screen, c => {
      if (!c || !c.properties || _.isArray(c.properties)) return false
      const loopProp = c.properties.loop || {};
      if (loopProp.isEnabled && loopProp.isShowPreloader) {
        if (loopProp.dataSource && loopProp.dataSource.search('Backendname') == -1) return true
      }
      return false;
    });
    if (index !== -1) {
      commit('setIsPreload', true);
    }else{
      // console.log(' setIsPreload ------  11111  window.showloopimages = true');
      // window.showloopimages = true
    }

    // service function, needs for using vuex in utils/screen
    passStateToScreenUtils({getters, state, commit});

    // service function, needs for using vuex in utils/hashtags
    passStateToHashtagsUtils({getters, state, rootState, commit, dispatch});

    // console.time('[SCS]: buildScreen')
    buildScreen(screen.components, screen.uuid);
    // console.timeEnd('[SCS]: buildScreen')

    // console.time('[SCS]: setOriginalData')
    commit('setOriginalData', _.cloneDeep(screen));
    // console.timeEnd('[SCS]: setOriginalData')

    // #Screen:$#
    commit('hashtags/mergeStateByKey', {
      key: 'Screen',
      data: _.omit(screen, ['actions', 'components', 'css', 'properties'])
    }, { root: true });

    // ** create list of components that contains hastags (dependencies.components)
    // ** create list of componetts with Loop enabled (dependencies.loopComponents)
    // ** and save this lists in store.dependencies
    // ** create list of hashtags (hashTags) that are used on current screen
    console.time('[SCS]: collectDependencies') // from 30ms
    let { dependencies } = collectDependencies(screen);
    console.timeEnd('[SCS]: collectDependencies')
    commit('setDependencies', dependencies);

    if (!payload.isUpdateOnlyScreenData) {
      // ** init store.hashtags.Backendname with default values of components
      commit('hashtags/setStateByKey', {
        key: 'Backendname',
        data: {}
      }, { root: true })
      // console.time('[SCS]: getBackendnameHashtags') // ~3ms
      commit('hashtags/setStateByKey', {
        key: 'Backendname',
        data: getBackendnameHashtags(screen.components, rootState.hashtags)
      }, { root: true });
      // console.timeEnd('[SCS]: getBackendnameHashtags')
    }

    commit('setScreen', screen);

    console.time('loops') // ~ from 30ms
    generateLooping(screen.components, rootState.hashtags);
    console.timeEnd('loops')
    // ** re-index components if loop components exists
    commit('createScreenIndex', screen);

    // console.time('[SCS]: replaceHashtagsByDependencies') // ~2ms
    replaceHashtagsByDependencies();
    // console.timeEnd('[SCS]: replaceHashtagsByDependencies')

    // console.time('getValueHashtagsFromAPI')
    if (!payload.isUpdateOnlyScreenData) dispatch('getValueHashtagsFromAPI');//await
    // console.timeEnd('getValueHashtagsFromAPI')



    return true;
  },

  /**
   * Получение значений для хэштегов по АПИ
   * @param commit {vuex context}
   * @param dispatch {vuex context}
   * @param state {vuex context}
   * @param rootState {vuex context}
   * @param hashTags - list group hashtags
   * @returns {Promise<void>}
   */
  async getValueHashtagsFromAPI({ commit, dispatch, state, rootState }, params = {}) {

    const { hashTags } = params
    const { appid, objid, screenid } = this.app.context.query;
    const applicationId = Number(appid);
    const objectId = Number(objid);
    const extraParams = {
      ..._.omit(rootState.hashtags, ['ObjectsFilter', 'EventsFilter', 'ListsFilter', 'Operation', 'List']),
      Globals: { applicationId, objectId }
    }

    const options = {
      Application: {
        url: '/hashtags/applications',
        data: {
          ids: [ applicationId ],
        }
      },
      Object: {
        url: '/hashtags/objects',
        data: {
          ids: [ objectId ],
          applicationId,
        },
      },
      ObjectsFilter: {
        url: '/hashtags/filters/objects',
        data: { applicationId, objectId, extraParams }
      },
      EventsFilter: {
        url: '/hashtags/filters/events',
        data: { applicationId, objectId, extraParams }
      },
      ListsFilter: {
        url: '/hashtags/filters/lists',
        data: { applicationId, objectId, extraParams }
      },
      List: {
        url: '/hashtags/list',
        data: {
          applicationId: Number(appid),
        },
      },
      System: {
        url: '/hashtags/system',
        data: { applicationId },
      },
      Tax: {
        url: '/hashtags/taxes',
        data: { applicationId },
      },
      Tenant: {
        url: '/hashtags/tenant',
        data: {}
      },
      Payment: {
        url: '/hashtags/payments',
        data: { applicationId }
      }
    };

    // ** формируем набор значений локальных хэштегов, которые предварительно заменим перед запросом серверных
    // let hashTagsVaues = _.omit(extraParams, ['Object', 'Payment'])
    // let arrRe = getRegExpTpl(hashTagsVaues)

    // ** объект, в который будут добавлены серверные хэштеги
    let groupHashTags = {}


    // let dependencies = []
    let dependencies

    if ( hashTags ) {
      dependencies = new Array(hashTags.length)
      for (let i = 0; i < hashTags.length; ++i) {
        dependencies[i] = { paths: [{ value: `#${_.trim(hashTags[i], '#')}#` }] }
      }
    } else {
      dependencies = Object.values(Object.assign({}, state.dependencies.components));
      for (let el of state.dependencies.loopComponents) {
        dependencies.push({ paths: [{ value: `#${el.dataSource}#` }] })
      }
    }
    console.log('dependencies 000  ', dependencies)

    // ** используется для исключения дублирующихся хэштегов, т.к. бэк не умеет отфильтровывать дубликаты
    const tempHashtags = new Set()
    const usedHashtags = new Set()
    const operationInLoopNames = new Set()

    dependencies.forEach(el => {
      for (let path of el.paths) {
        let str = path.value

        // for (let re of Object.values(arrRe)) {
        //   str = replacer(str, hashTagsVaues, re, false, false, false)
        // }

        const findHashtags = str.match(/#(\w+):[^#]+#/g) || null;

        if (findHashtags) {
          for (let tag of findHashtags) {
            usedHashtags.add(tag)
            if (tempHashtags.has(tag)) return;

            const key = tag.substring(1, tag.indexOf(':'));
            if (!groupHashTags[key]) groupHashTags[key] = []

            if (['ObjectsFilter', 'EventsFilter', 'ListsFilter', 'Payment'].includes(key)) {

              // ** отрезаем лишний "хвост" у перечисленных хэштегов, чтобы бэк нормально возвращал данные
              tag = tag.match(/#([^#]+?[:].+?)(?=:\d|[@[#])/)[1]

              let pagination = null;

              // ** check if pagination needed (enabled) for this hashtag
              let loopDependency = state.dependencies.loopComponents.find(el => el.dataSource == tag);
              if (loopDependency && (loopDependency.pagination || {}).enabled) {
                pagination = _.omit(loopDependency.pagination, ['enabled']);

                // ** there are bug in API: pagination parameters must be (int) 10, not (string) "10"
                pagination.pageSize = parseInt(pagination.pageSize) || 4;
              }

              if (!tempHashtags.has(tag)) groupHashTags[key].push({ tag: `#${tag}#`, objectId: Number(objid), pagination })

            } else if (['List'].includes(key)) {
              // ** отрезаем лишний "хвост" у хэштега, чтобы бэк нормально возвращал данные
              tag = tag.match(/#([^#0-9]+(\d+)?)/)[1]
              if (!tempHashtags.has(tag)) groupHashTags[key].push({ tag: `#${tag}#` });

            } else if ('Operation' === key) {
              if (!tempHashtags.has(tag)) {
                const path = tag.replace(/#/g, '');
                let loopDependency = state.dependencies.loopComponents.find(el => el.dataSource == path);

                if (loopDependency && !operationInLoopNames.has(tag)) {
                  const operationName = path.split(':')[1];
                  operationInLoopNames.add(operationName);

                  let pagination = null;
                  if ((loopDependency.pagination || {}).enabled) {
                    pagination = _.omit(loopDependency.pagination, ['enabled']);

                    // ** there are bug in API: pagination parameters must be (int) 10, not (string) "10"
                    pagination.pageSize = parseInt(pagination.pageSize) || 4;
                  }

                  groupHashTags[key].push({ hashTag: tag, operationName, pagination })
                }
              }
            } else {
              // ** отрезаем лишний "хвост" у перечисленных хэштегов, чтобы бэк нормально возвращал данные
              if ('Object' === key)
                tag = `#${tag.match(/#([^#]+?[:].+?)(?=:\d|[@[#])/)[1]}#`

              groupHashTags[key].push(tag);
            }

            tempHashtags.add(tag)
          }
        }
      }
    })

    this.$devmode.send({ action: 'setUsedHashtags', payload: usedHashtags })
    this.$devmode.send({ action: 'setDependencies', payload: dependencies })

    let res = null;
    let runOperation = null;
    try {
      // console.time('axios get all')
      let promises = [];

      for (const key of Object.keys(groupHashTags)) {
        const props = options[key];

        if (!props) continue;

        const tags = groupHashTags[key];
        promises.push(this.$axios.$post(props.url, { ...props.data, tags }));
      }

      res = await Promise.all(promises);
      // console.timeEnd('axios get all')

      let recievedHashtags = {};

      _.each(res, item => {
        if ( _.isEmpty(item.data) ) return;

        const resData =  Array.isArray(item.data) ? item.data : [item.data];
        resData.forEach( v => {

          // let data = v.attributes;
          const paramsHashTag = v.attributes.tag ? _.trim(v.attributes.tag, '#').split(':') : '';
          const keyApiHashtags = v.type ? v.type : paramsHashTag[0];
          let path = '';
          let data = v.attributes;

          if (paramsHashTag) {
            path = paramsHashTag.slice(1).join('.');
            data = v.attributes['data'];
          }

          // if (v.attributes.tag && v.attributes.tag.search('Filter') != -1) {
          if (['ObjectsFilter', 'EventsFilter', 'ListsFilter'].includes(keyApiHashtags)) {
            let value = _.lowerFirst(paramsHashTag[2]);
            if (value.search(/selfData|selfValue|selfCount|selfPrecentChange/) != -1) {
              value = _.lowerFirst(value.replace('self', ''));
            }
            data = v.attributes[value];
          }

          let HTListNeedClear = true;
          if (path) {
            if (keyApiHashtags === 'List' && !!parseInt(paramsHashTag[paramsHashTag.length-1])) {
              data = _.setWith( {}, path, Object.freeze(data), Object);
              HTListNeedClear = false
            } else {
              data = _.set( {}, path, Object.freeze(data));
            }
          }

          // if (keyApiHashtags && keyApiHashtags.toLowerCase() == 'list') {
          //   // const paramsHashTag = _.trim(v.attributes.tag, '#').split(':');
          //   const path = _.join( _.drop(paramsHashTag), '.' );
          //   keyApiHashtags = paramsHashTag[0];
          //   data = _.set( {}, path, Object.freeze(data.data) );
          // }

          // ** check if pagination enabled for this hashtag
          // ** then append dataset in store.hashtags instead replace it
          // debugger
          let resultMerged = false;

          if ( (v.attributes ||{}).tag ) {
            let tag = _.trim(v.attributes.tag, '#')
            let loopDependency = state.dependencies.loopComponents.find(el => el.dataSource == tag);

            if (loopDependency && (loopDependency.pagination || {}).enabled) {

              const { pageSize } = loopDependency.pagination
              const isGrowingPage = _.get(data, path).length >= pageSize

              if (loopDependency.pagination.page > 1) {
                if (v.attributes.data.length) {
                  recievedHashtags[keyApiHashtags] = _.merge(rootState.hashtags[keyApiHashtags], recievedHashtags[keyApiHashtags])

                  const initData = _.get(recievedHashtags[keyApiHashtags], path)
                  const page = Math.ceil(initData.length / pageSize)
                  const countItemsByPreLastPage = (page - 1) * pageSize
                  const countLastItems = initData.length - countItemsByPreLastPage

                  recievedHashtags[keyApiHashtags] = _.mergeWith(recievedHashtags[keyApiHashtags], data, (objValue, srcValue) => {
                    if (_.isArray(objValue)) {
                      if (countLastItems > 0 && countLastItems < pageSize) {
                        objValue.splice(countItemsByPreLastPage, countLastItems, ...srcValue)
                        return objValue
                      }
                      return objValue.concat(srcValue);
                    }
                  });
                  // commit('hashtags/mergeStateByKey', {key: keyApiHashtags, data, _mergeType: 'append'}, { root: true });
                }
                resultMerged = true;
                HTListNeedClear = false

                if (isGrowingPage) loopDependency.pagination.page++
              }

              if (loopDependency.pagination.page <= 1 && isGrowingPage) loopDependency.pagination.page++

            }

            // Vue.set(loopDependency.pagination, 'page', loopDependency.pagination + 1);
          }

          // ** Clear List ht-cache for refresh with actual data **
          if (HTListNeedClear && keyApiHashtags === 'List') {
            commit('hashtags/mergeStateByKey', { key: 'List', data: _.set({}, path, [])}, { root: true });
          }
          // **

          if ( !resultMerged ) {
            recievedHashtags[keyApiHashtags] = _.merge(recievedHashtags[keyApiHashtags], data);
            // commit('hashtags/mergeStateByKey', {key: keyApiHashtags, data, _mergeType: 'default'}, { root: true });
          }
        });
      });

      commit('hashtags/mergeState', recievedHashtags, { root: true });





      // // ** replace this line with next line (uncomment back when something goes wrong)
      // //await dispatch('setScreen', { screen: state.originalData, updateHashtags: false, refreshScreen });
      //
      // // ** update only components that contains updated hashtags, recieved with `getValueHashtagsFromAPI`
      // // console.log('groupHashTags: ', groupHashTags);
      // // console.time('updateComponents')
      // for (let key in groupHashTags) {
      //   // if(runOperation && key=="Operation"){
      //   //   continue
      //   // }
      //   console.log(' -- updateComponents key ', key);
      //   (dispatch('updateComponents', key));
      // }
      // // console.timeEnd('updateComponents')
      //
      // // if(runOperation){
      // //   console.log(' -- updateComponents runOperation ', runOperation);
      // //   (dispatch('updateComponents', 'Operation'));//fixFlashing 3
      // // }







      // Operations in loops
      if (operationInLoopNames.size > 0) {
        console.log(' setIsPreload caller 560 getValueHashtagsFromAPI');
        commit('setIsPreload', true);
        let operationsList = [];

        try {
          const { data } = await this.$axios.$get('/operations', {
            params: {
              page: 1,
              pageSize: operationInLoopNames.size,
              names: JSON.stringify([...operationInLoopNames])
            }
          });

          if (!data) throw {errors: [{title: 'Error', detail: 'Failed get operations list'}]};

          operationsList = data;
        } catch(e) {
          console.error(e);
          const { errors } = (e.response || {}).data || e;
          if (errors) {
            const code = parseInt(e.response && e.response.status);
            if (code !== 403) {
              _.each(errors, error => {
                this.$q.dialog({ title: error.title, message: error.detail });
              });
            }
          }
        }

        let executableOperationPromises = []
        console.log('groupHashTags[\'Operation\'] pagination_operation', groupHashTags['Operation'])
        console.log('groupHashTags pagination_operation', groupHashTags)
        for (const { operationName, hashTag, pagination } of groupHashTags['Operation']) {
          const findOperation = operationsList.find(operation => operation.attributes.Name == operationName);
          if (findOperation) {
            let ExtraParams = {};
            const operationHashtags = findOperation.attributes.Hashtags;

            for (const oh of operationHashtags) {
              const path = _.trim(oh, '#').replace(/\:/g, '.');
              const value = _.get(rootState.hashtags, path);
              _.set(ExtraParams, path, value);
            }

            const path3 = _.trim(hashTag, '#').replace(/\:/g, '.');
            console.log('path 3 operationHashtags pagination_operation', path3);
            let loopHastags3 = _.get(rootState.hashtags, path3, []);
            console.log('loopHastags 3 operationHashtags pagination_operation', loopHastags3);
            const arraypath3 = path3.split('.')
            const isOperation3 = arraypath3[0] === 'Operation'


            if (isOperation3 && loopHastags3.length > 0) {
              let now = new Date().getTime();
              let diff = now - rootState.lastUpdateDate;
              console.log('diff time hashtags update pagination_operation', diff);
              console.log('pagination pagination_operation', pagination);

              if (diff < 100){//if last updated was less 0/1 sec before//?????????? need??????
                //continue;//fixFlashing 2
              }

              if (diff < 60000)//if last updated was less 60 sec before
              {
                if (pagination !== null) {
                  if (typeof pagination.page !== 'undefined') {
                    if ( ((pagination.pageSize) < loopHastags3.length) ) {//if was previous loader executing
                          pagination.pageSize = loopHastags3.length //than increase pageSize to current position
                          console.log(' pagination_operation pagination.pageSize 1 ', pagination.pageSize);
                    }
                  }
                }
              }
            }

            executableOperationPromises.push(
              new Promise(async resolve => {
                try {
                  const { data } = await this.$axios.$post('/operations/execute', {
                    applicationId: Number(appid),
                    objectId: Number(objid),
                    screenId: Number(screenid),
                    operationId: Number(findOperation.id),
                    ExtraParams,
                    ...pagination
                  })

                  resolve({
                    name: operationName,
                    value: data,
                    hashTag, pagination
                  })
                }
                catch(e) {
                  console.error(e)
                  resolve({
                    name: operationName,
                    value: null
                  })
                }
              })
            )
          }
        }
        console.log('executableOperationPromises ', executableOperationPromises)
        const resultExecutedOperations = await Promise.all(executableOperationPromises);

        for (const { name, value, hashTag } of resultExecutedOperations) {
          if (!value) continue;

          const tag = _.trim(hashTag, '#')
          const path = tag.split(':').slice(2).join('.')
          let data = _.get(value, path) || []
          let loopDependency = state.dependencies.loopComponents.find(el => el.dataSource == tag);

          if (loopDependency && (loopDependency.pagination || {}).enabled) {
            const { pageSize, page } = loopDependency.pagination
            const isGrowingPage = data.length >= pageSize

            if (page > 1) {
              let t = _.get(rootState.hashtags.Operation[name], path)
              let initData = _.cloneDeep(t)
              const currentPage = Math.ceil(initData.length / pageSize)
              const countItemsByPreLastPage = (currentPage - 1) * pageSize
              const countLastItems = initData.length - countItemsByPreLastPage

              if (countLastItems > 0 && countLastItems < pageSize) {
                data = initData.splice(countItemsByPreLastPage, countLastItems, ...data)
              }
              data = [...initData, ...data]

              if (isGrowingPage) loopDependency.pagination.page++
            }

            if (page <= 1 && isGrowingPage) loopDependency.pagination.page++
          }

          commit('hashtags/setStateByKey', {key: `Operation.${name}.${path}`, data}, { root: true });
        }
        console.log('updateComponents Operation skip');
        runOperation = true

        // if(runOperation){
        //   console.log(' -- updateComponents runOperation ', runOperation);
        //   (dispatch('updateComponents', 'Operation'));//fixFlashing 3
        // }

        res = resultExecutedOperations
      }







      // ** replace this line with next line (uncomment back when something goes wrong)
      //await dispatch('setScreen', { screen: state.originalData, updateHashtags: false, refreshScreen });

      // ** update only components that contains updated hashtags, recieved with `getValueHashtagsFromAPI`
      // console.log('groupHashTags: ', groupHashTags);
      // console.time('updateComponents')
      for (let key in groupHashTags) {
        if(runOperation && key=="Operation"){
          continue
        }
        console.log(' -- updateComponents key ', key);
        (dispatch('updateComponents', key));
      }
      // console.timeEnd('updateComponents')

      if(runOperation){
        console.log(' -- updateComponents runOperation ', runOperation);
        (dispatch('updateComponents', 'Operation'));//fixFlashing 3
      }

      console.log(' getValueHashtagsFromAPI -- prepare updateComponents  Promise.all(gPromises) ', gPromises.length );
      console.log(' gPromises ', gPromises );

      if (gPromises.length > 0) {






        //window.requestAnimationFrame(  function () {
          console.log(' start requestAnimationFrame setIsPreload  ');
          console.time('requestAnimationFrame 0')

          for (let elgP of gPromises) {
            // ** replace old set of looped components with new one
            commit('updateLoopComponents', { uuid:elgP.uuid, components: elgP.data });
            // ** looping shifts all components on screen => need re-indexing before looping next component
            commit('createScreenIndex', elgP.state_data);
          }

          console.timeEnd('requestAnimationFrame 0')
          console.log(' end requestAnimationFrame setIsPreload');
        //});




      } else {

      }
      gPromises = [];
      gPromisesUUid = [];



    }
    catch (e) {
      console.error(e)
      return e;
    }
    finally {
      console.log(' setIsPreload caller 759 getValueHashtagsFromAPI');
      commit('setIsPreload', false);
      console.log(' setIsPreload ------   window.showloopimages = true');
      window.showloopimages = true;

      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      var screenidvar = urlParams.get('screenid')
      var elementScroll = document.getElementsByClassName('q-page screen__content fit')[0];
      //console.log('setIsPreload 0 ------ elementScroll ', elementScroll);
      if (elementScroll) {
        //console.log('setIsPreload 1 ------ elementScroll ', elementScroll);
        elementScroll.addEventListener('scroll', (event) => {
          var a = event.target.scrollTop;
          var b = event.target.scrollHeight - event.target.clientHeight;
          // console.log('pagination_operation a b ',a,b);
          //console.log(screenidvar);
          window.lastScrollPosition[screenidvar] = a;
          // console.log('setIsPreload ------ FIX window.lastScrollPosition pagination_operation', window.lastScrollPosition);
          // let lastKnownScrollPosition = window.scrollY;
          //console.log('setIsPreload ------ window.scrollY', lastKnownScrollPosition);
          //console.log('setIsPreload ------ event ', event);
        });
      }


      if (elementScroll) {
        if (window.lastScrollPosition.hasOwnProperty(screenidvar)) {

          // window.mbst.bus.$on('global:componentAfterActionsExecute', async e => {
          //   console.log('setIsPreload ------ 2 componentAfterActionsExecute go to last position pagination_operation------ elementScroll ', screenidvar, window.lastScrollPosition[screenidvar]);
          //   elementScroll.scrollTo({
          //     top: window.lastScrollPosition[screenidvar],
          //     left: 0,
          //     behavior: 'smooth',
          //   });
          // });


          // setTimeout(() => {
          //   console.log('setIsPreload ------ 2 2000 go to last position pagination_operation------ elementScroll ', screenidvar, window.lastScrollPosition[screenidvar]);
          //   elementScroll.scrollTo({
          //     top: window.lastScrollPosition[screenidvar],
          //     left: 0,
          //     behavior: 'smooth',
          //   });
          // }, 2000);
          //
          // setTimeout(() => {
          //   console.log('setIsPreload ------ 2 3000 go to last position pagination_operation------ elementScroll ', screenidvar, window.lastScrollPosition[screenidvar]);
          //   elementScroll.scrollTo({
          //     top: window.lastScrollPosition[screenidvar],
          //     left: 0,
          //     behavior: 'smooth',
          //   });
          // }, 3000);

          setTimeout(() => {
            // console.log('setIsPreload ------ 2 1000 go to last position pagination_operation------ elementScroll ', screenidvar, window.lastScrollPosition[screenidvar]);
            elementScroll.scrollTo({
              top: window.lastScrollPosition[screenidvar],
              left: 0,
              behavior: 'smooth',
            });
          }, 1000);

        }
      }


      return res;
    }





  },

  // вызывается при каждом из компонентов
  changeComponentValue({ commit, dispatch, state, getters, rootState }, payload) {

    let { name, value, uuid } = payload;

    let component;

    if (!uuid && name) {
      component = getters.componentByName(name);
      if (component) {
        uuid = component.uuid
      }
    }

    // ** save cache policy settings for changed component **
    // ** parhaps move to another place??? (utils/hashtags/collectDependencies???) **
    if (uuid) {
      component = component || getters.component(uuid);
      const cacheValue = _.get(state, ['__cachepolicy', (uuid)])
      if (cacheValue === undefined || cacheValue !== component.properties.iscached) {
        let policy = state['__cachepolicy'] || {}
        policy[uuid] = component.properties.iscached
        state['__cachepolicy'] = policy
      }
    }
    // **

    let setValueData = {uuid, value};

    if (_.isPlainObject(value)) {
      value = _.omit(value, ['selected', 'conditions']);
    } else if (_.isArray(value)) {
      commit('updateSelected', setValueData);
    } else {
      commit('setValue', setValueData);
    }

    const key = 'Backendname';

    commit('hashtags/mergeStateByKey', {
      key,
      data: { [component.properties.backendname]: value }
    }, { root: true });
    console.log(' -- updateComponents key + \':\' + name ', key + ':' + name);
    (dispatch('updateComponents', key + ':' + name));


  },

  /**
   * Update components, that depend from particular hashtag
   *
   * argument `payload` contains hashtagname for replacing
   * more precise filtration is achieved by using more structured value in `payload`
   * for example, `Backendname:somefield` instead `Backendname`
   *
   * @param {Object} store
   * @param {String} payload
   * @returns {void}
   **/
  updateComponents({ commit, state, getters, rootState }, payload) {

    const tag = payload + (payload.indexOf(':') > -1 ? '' : ':')
    const exceptTag = '#Loop:'
    console.log('updateComponents  payload', payload)
    // console.log('AAAAAA', payload)
    // console.time('UPDATECOMPONENTS ' + tag)

    // ** replace hashtags in components except #Loop **
    // console.time('[updComp] dependencyFilter')
    const dependenciesComponents = state.dependencies.components
    const filteredDependenciesComponents = dependenciesComponents.filter(dependency => {
      let paths = dependency.paths.filter(path => (path.value.indexOf(tag) !== -1 && path.value.indexOf(exceptTag) === -1)) //&& path.value.indexOf(exceptTag) === -1))
      return paths.length
    })
    const dependencyFilter = new Array(filteredDependenciesComponents.length)

    for (let i = 0; i < filteredDependenciesComponents.length; ++i) {
      const dependency = filteredDependenciesComponents[i]
      let paths = dependency.paths.filter(path => (path.value.indexOf(tag) !== -1 && path.value.indexOf(exceptTag) === -1)) // && path.value.indexOf(exceptTag) === -1))
      if (paths.length) {
        dependencyFilter[i] = Object.assign({}, dependency, { paths })
      }
    }
    // console.timeEnd('[updComp] dependencyFilter')

    if (dependencyFilter.length) {
      replaceHashtagsByDependencies(dependencyFilter)
    }
    console.log('dependencyFilter ', dependencyFilter)
    // ** !!! reactive actions (experimental) !!!
    // console.time('[updComp] reactiveComponents')
    const reactiveComponents = dependencyFilter.filter(el => el.paths.find(path => path.reactive === true) !== undefined)
    console.log('reactiveComponents ', reactiveComponents)
    if (reactiveComponents.length) {
      for (let el of reactiveComponents) {
        let component = getters.component(el.uuid)
        // component.$actions.execute({ uuid: component.uuid, eventType: 'experimental' });
        window.mbst.bus.$emit('global:actionsExecute', { uuid: component.uuid, eventType: 'reactive' })
      }
    }
    // console.timeEnd('[updComp] reactiveComponents')

    // ** rebuild Loop components **
    // console.log(dependencyFilter)
    // console.time(`[updComp ${tag}] rebuild loop`)
    let loopComponents = new Set
    for (let el of dependencyFilter) {
      el.parentLoopUUID ? loopComponents.add(el.parentLoopUUID) : null
    }
    // dependencyFilter.forEach(el => el.parentLoopUUID ? loopComponents.add(el.parentLoopUUID) : null )
    console.log('loopComponents ', loopComponents)//here check
    const loopFilter = state.dependencies.loopComponents.filter(
      dependency => ((dependency.dataSource && dependency.dataSource.indexOf(_.trim(tag, '#')) !== -1)) || loopComponents.has(dependency.uuid)
    )

    if (typeof dependency !== 'undefined')
    console.log('dependency ', dependency)

    for (let loopDep of loopFilter) {
      const { uuid } = loopDep
      let component = getters.component(uuid)
      //let component = getters.originalComponent(uuid);

      const iterateComponent = _.omit(component, ['isAnchorLoop', 'anchorLoop'])
      console.log('iterateComponent ', iterateComponent)
      let size
      if (iterateComponent.name === 'mbst-slider__slide') size = (component.anchorLoop||{}).count || null
      // console.time('[loopfilter] loopComponent')
      const { data } = loopComponent(iterateComponent, rootState.hashtags, iterateComponent.parentUUID, size, 0, false);
      console.log('data ', data)
      // console.timeEnd('[loopfilter] loopComponent')
      if (data)
      if (data.length > 0) {



        console.log('updateLoopComponents in actions skip ', data.length)

        // gPromises.push( new Promise((resolve) => { resolve(true) }))

          // ** replace old set of looped components with new one

          //commit('updateLoopComponents', { uuid, components: data })

          component.anchorLoop = { parentUUID: component.parentUUID, count: data.length }

          // ** looping shifts all components on screen => need re-indexing before looping next component

          // console.log('state.data ', state.data)
          // console.log('components: data ', data)

          //commit('createScreenIndex', state.data)

        if (!gPromisesUUid.includes(uuid)) {
          gPromisesUUid.push(uuid);
          gPromises.push({ uuid: uuid, data: data, state_data: state.data });
        } else {

          // wrong
          // console.log(' --- uuid in gPromises --- data.length:', data.length);
          // console.log(' --- uuid in gPromises --- gPromises.length:', gPromises.length);
          // let dataForMerge = _.cloneDeep(data);
          //
          // gPromises.filter(someobject => someobject.uuid == uuid)
          //   .forEach(someobject2 => {
          //     //console.log(' --- data someobject2', someobject2);
          //     someobject2.data.forEach(someobject3 => {
          //       //console.log(' --- data someobject3', someobject3);
          //       dataForMerge = _.reject(dataForMerge, { uuid: someobject3.uuid });
          //     });
          //     console.log(' --- !! uuid in gPromises --- dataForMerge.length:', dataForMerge.length);
          //     someobject2.data = someobject2.data.concat(dataForMerge);
          //     console.log(' --- !! uuid in gPromises --- someobject2.data length result:', someobject2.data.length);
          //   });

        }




      }
    }
    // console.timeEnd(`[updComp ${tag}] rebuild loop`)
    // **

    //window.mbst.bus.$emit('global:componentsUpdated', { tag: _.trim(tag, ':') })


    // console.timeEnd('UPDATECOMPONENTS ' + tag)

  },

}
