import _ from 'lodash';
_.mixin(require('lodash-deep'));
import { uuid } from 'vue-uuid';
import { replaceHashtagsByDependencies, functions, replaceHashtagsInLoop, getBackendnameHashtags } from './hashtags';
import { isSuccessConditions } from './conditions';

import Vue from 'vue';

const isSetHashTags = str => (str.search(/#([^#]+?[:].+?)(?=[#:@])#/g) != 1);
const findHashTags = str => str.match(/#([^#]+?[:].+?)(?=[#:@])#/g);
const getGroupNameHashTag = str => str.match(/([^#]+?)(?=:)/)[0];

var store;

export const passStateToScreenUtils = (s) => {
  store = s
}

export const buildScreen = (components, parentUUID) => {
  for (let component of components) {
    delete component.parentUUID;
    component.parentUUID = parentUUID;

    if (component.items) {
      buildScreen(component.items, component.uuid)
    }

    if (component.components) {
      buildScreen(component.components, component.uuid)
    }
  }
};


/**
 *
 * @param {Object} screen
 * @returns {Array}
 */
export const collectDependencies = (screen, isCollectHahtags = false) => {
  let dependencies = {
    components: [],
    loopComponents: []
  };
  let hashTags = [];

  const collectHashtags = str => {
    if (!str) return false;
    const findHashtags = str.match(/#([^#]+?[:].+?)(?=[#:@])#/g) || null;

    if (findHashtags) {
      _.each(findHashtags, h => {
        const key = h.substring(1, h.indexOf(':'));

        if (['ObjectsFilter', 'EventsFilter', 'ListsFilter', 'Payment', 'Object'].includes(key)) {
          hashTags.push(`${h.match(/#([^#]+?[:].+?)(?=:\d|[@[#])/)[0]}#`);

        } else if (key == 'List') {
          let match = h.match(/#[^#].*?(\d+|#)/);
          if (match) {
            const res = match[0].replace('[', ':');
            hashTags.push(`${res}#`);
          }
        } else if (key !== 'Loop') {
          hashTags.push(`${h.match(/#([^#]+?[:].+?)(?=[#:@])/)[0]}#`);
        }
      });
    }
  };

  // const deepMapValues (component, callback, path = '') {
  //   for (let key in component) {
  //     callback(component[key], path + '.' + key)
  //   }
  // }

  const _searchDependencies = function (component) {

    let parentLoopUUID = arguments[1];

    if ( ((component.properties||{}).loop || {}).isEnabled ) {
      const { dataSource } = component.properties.loop;
      if (isCollectHahtags) collectHashtags(dataSource);

      // ** remove symbols `#` from loop.dataSource
      // ** for preventing hashtag replacing in `dataSource` propperties
      let dependency = { uuid: component.uuid, dataSource: _.trim(dataSource, '# '), pagination: { enabled: false } };
      // let dependency = { uuid: component.uuid, dataSource: dataSource, pagination: { enabled: false } };
      if ((component.properties.loop.pagination || {}).enabled) {
        dependency.pagination = component.properties.loop.pagination;
      }
      dependencies.loopComponents.push(dependency);

      parentLoopUUID = component.uuid;
    }

    let o = { uuid: component.uuid, paths: [] };
    if (parentLoopUUID) {
      o['parentLoopUUID'] = parentLoopUUID;
    }
    _.deepMapValues(component, (value, path) => {
      if (
        path.indexOf('items') == -1 &&
        path.indexOf('components') == -1 &&
        path.indexOf('loop') == -1
      ) {
        if (typeof value === 'string' && (value.search(/#(.+?)#/g) != -1 || value.search(/=\(.+?\)=|=\(\)=/g) != -1)) {
          if (isCollectHahtags) collectHashtags(value);

          if ( path.indexOf('actions') !== -1 ){
            const keys = path.split('.')
            const reactive = (component['actions'][keys[1]]||{}).event === 'reactive'
            o.paths.push({ path, value, reactive });
          } else {
            o.paths.push({ path, value });
          }
        }
      }
    });
    if (!_.isEmpty(o.paths)) dependencies.components.push(o);

    if (component.items) {
      for (const el of component.items) {
        _searchDependencies(el, parentLoopUUID)
      }
    }
    if (component.components) {
      for (const el of component.components) {
        _searchDependencies(el, parentLoopUUID)
      }
    }
  }

  _searchDependencies(screen);

  return { dependencies, hashTags: _.uniq(hashTags) };
};


export const generateLooping = (components, hashtags) => {
  let l = [];
  let cnt = 0;
  for (let index in components) {
    let component = components[index];

    if ( ((component.properties||{}).loop || {}).isEnabled /*&& !component.isAnchorLoop*/ && component.name !== 'mbst-slider__slide') {

      if ((component.properties.loop || {}).dataSource && (component.properties.loop || {}).aliasName) {

        let pageSize = null
        if ((component.properties.loop.pagination || {}).enabled) {
          pageSize = component.properties.loop.pagination.pageSize
        }

        let { data, targetUUID } = loopComponent(_.omit(component, ['isAnchorLoop']), hashtags, component.parentUUID, pageSize);

        component.isAnchorLoop = true;
        // component.anchorLoop = { parentUUID: component.parentUUID, count: data.length }
        component.anchorLoop = { count: data.length }

        if ( !(component.properties.loop.pagination || {}).enabled ) {
          buildScreen(data, component.parentUUID);
          l.push({index, data, targetUUID})

        } else {

          let scroller = {
            name: 'mbst-infinityscroller',
            aliasName: 'infinityScroller',
            uuid: uuid.v1(),
            components: [_.cloneDeep(component), ...data],
            properties: {},
            loopComponentUUID: component.uuid,
            parentUUID: component.parentUUID
          };
          scroller.components[0].anchorLoop = { parentUUID: scroller.uuid, count: data.length }
          buildScreen(scroller.components, scroller.uuid);
          Vue.set(components, index, scroller)
          cnt++;
        }
      }
    } else {

      if (component.items && !_.isEmpty(component.items)) {
        cnt += generateLooping(component.items, hashtags);
      }
      if (component.components && !_.isEmpty(component.components)) {
        cnt += generateLooping(component.components, hashtags);
      }
    }
  }

  for (let item of _.reverse(l)) {
    components.splice(++item.index, 0, ...item.data)
  }
  // _.reverse(l).forEach(item => {
  //   components.splice(++item.index, 0, ...item.data);
  // })

  return (cnt + l.length);
};

export const loopComponent = (component, hashtags, parentComponentUUID, size, offset = 0, grabbingBackendnameHashtags = true) => {
  // console.time('LOOPCOMP FUNC')
  // let innerHashtags = { Loop: {} };
  if (!component) return false
  const { dataSource, aliasName } = (component.properties||{}).loop;
  if (!dataSource || !aliasName) return false;

  // TODO: аааааа дублирование кода!!!! в хэштегах уже есть такое!
  let temp = _.trim(dataSource, '# ');
  let opt = null;
  let params = null;
  if (temp.search(/@[^@]+?\(.+?\)|@.+?\(\)/) != -1) {
    opt = temp.match(/@([^@]+?)\(/)[1]; // что сделать с данными (операция SUM, JOIN, etc.)
    if (temp.search(/@[^@]+?\((.+?)\)/) != -1) {
      params = temp.match(/@[^@]+?\((.+?)\)/)[1].replace(/\\/g, '');
    }
    temp = temp.replace(/@[^@]+?\((.+)/, '');
  }
  const path = temp.replace(/\:/g, '.');
  let loopHastags = _.get(hashtags, path, []);

  loopHastags = opt ? functions[opt](loopHastags, params) : loopHastags;

  // console.log('component', component)

  // innerHashtags.Loop[aliasName] = loopHastags;

  // Loop components
  let loopingComponents = [];
  let totalCountHashtag = 0
  if (loopHastags)
  if (typeof loopHastags.length !== 'undefined') {
    const totalCountHashtag = loopHastags.length
  }

  if (loopHastags) {
    if (size) {
      loopHastags = loopHastags.slice(offset, offset + size)
    }

    let counter = 0
    // let copyIndex = -1;
    // let loopHastagsLength = Array.isArray(loopHastags) ? loopHastags.length : Object.values(loopHastags).length;

    for (let i in loopHastags) {
      if (_.isEmpty(loopHastags[i])) continue;

      if (typeof(loopHastags[i]) !== 'object') {
        loopHastags[i] = { value: loopHastags[i] };
      }

      let copy = _.cloneDeep(_.omit(component, 'properties.loop'));
      // console.log(copy)

      if (!copy.localHashtags) copy.localHashtags = { Loop: {} }

      copy.localHashtags.Loop = {
        ...copy.localHashtags.Loop,
        [aliasName]: {
          ...loopHastags[i],
          __index: i
        }
      }

      const ha = {
        ...hashtags,
        ...copy.localHashtags
      }

      const arrPath = path.split('.')
      const pathCtgr = arrPath.shift()
      let localHashtagPath = `${path}.${i}`

      if (pathCtgr === 'Loop') {
        const loopName = arrPath.shift()
        if (copy.loopData.localHashtagPaths.hasOwnProperty(loopName)) {
          localHashtagPath = `${copy.loopData.localHashtagPaths[loopName]}.${arrPath.join('.')}.${i}`
        }
      }

      // ** re-assign UUID for copy after processing nested components
      if (!copy.loopData) copy.loopData = { localHashtagPaths: {} }
      copy.loopData = {
        anchorUUID: component.uuid,
        loopAlias: aliasName,
        dataSource: path + '.' + (Number(i)+offset),
        index: counter,
        localHashtagPaths: {
          ...copy.loopData.localHashtagPaths,
          [aliasName]: localHashtagPath
        }

      }
      copy = assignUUIDDeep(copy);

      // console.time('generateLooping recursive')
      if (copy.components){
        generateLooping(copy.components, ha)
      }
      if (copy.items){
        generateLooping(copy.items, ha)
      }
      // console.timeEnd('generateLooping recursive')

      // replaceHashtag(copy, {Loop: { [aliasName]: Object.assign(loopHastags[i], {__index: i}) } }, 'Loop:'+aliasName);

      replaceHashtagsInLoop(copy, ha, 'Loop:'+aliasName);

      if (grabbingBackendnameHashtags) {
        const backendnameHashtags = getBackendnameHashtags([copy], ha)

        if (!_.isEmpty(backendnameHashtags)) {
          store.commit('hashtags/mergeStateByKey', {
            key: 'Backendname',
            data: backendnameHashtags
          }, { root: true });
        }
      }

      let isHidden = false
      if ( (copy.properties.visibility || {}).hidden !== undefined )  {
        isHidden = copy.properties.visibility.hidden;
        const conditions = copy.properties.visibility.conditions;
        if (conditions && conditions.length > 0) {
          isHidden = isSuccessConditions(conditions) ? !isHidden : isHidden;
        }
      }

      if (!isHidden) loopingComponents.push(copy);
      counter++

      delete copy.localHashtags
    }
  }
  // console.timeEnd('LOOPCOMP FUNC')
  return { targetUUID: parentComponentUUID, data: loopingComponents, totalCountHashtag };
};

const assignUUIDDeep = (component, parentUUID = null, loopData = null) => {
  component.originalUUID = component.originalUUID || component.uuid; // ** trick for loop in loop
  component.uuid = uuid.v1();

  if (loopData) {
    component.loopData = loopData
  }
  if (parentUUID) {
    component.parentUUID = parentUUID
  }
  if (component.items && !_.isEmpty(component.items)) {
    for (let el of component.items) {
      assignUUIDDeep(el, component.uuid, component.loopData)
    }
    // component.items.forEach( (el, i) => el = assignUUIDDeep(el, component.uuid, component.loopData) )
  }
  if (component.components && !_.isEmpty(component.components)) {
    for (let el of component.components) {
      assignUUIDDeep(el, component.uuid, component.loopData)
    }
    // component.components.forEach( (el, i) => el = assignUUIDDeep(el, component.uuid, component.loopData) )
  }
  return component;
}

export const getComponentByUUID = (container, uuid, callback) => {
  let component = store.getters.component(uuid, container);

  if (component.uuid && callback){
    callback(component);
  } else {
    return component;
  }
}

export const getComponentWith = (container, callback, func) => {
  if (func(container)) {
    callback(container);
  } else {
    _.each(container.components, component => {
      if (func(component)) {
        callback(component);
      }
      if (component.items) {
        _.each(component.items, item => {
          if (func(item)) {
            callback(item);
          } else {
            getComponentWith(item, callback, func);
          }
        });
      }
    });
  }
};

export const findComponentWith = (container, func) => {
  if (func(container)) {
    return container;
  } else {
    _.each(container.components, component => {
      if (func(component)) {
        return component;
      }
      if (component.items) {
        _.each(component.items, item => {
          if (func(item)) {
            return item;
          } else {
            return findComponentWith(item, func);
          }
        });
      }
    });
  }
};

export const findIndexOfComponentWith = (container, func) => {
  let res = {
    parentUUID: undefined,
    index: -1
  }
  if (func(container)) {
    res.index = 'container';
  } else {
    for (let [$index, component] of Object.entries(container.components)) {
      if (func(component)) {
        res.index = $index;
        res.parentUUID = component.parentUUID;
        return false;
      }
      if (component.items) {
        for(let [itemIndex, item] of Object.entries(component.items)) {
          if (func(item)) {
            res.index = itemIndex;
            res.parentUUID = item.parentUUID;
            return false;
          } else {
            res = findIndexOfComponentWith(item, func);
          }
        }
        // _.each(component.items, (item, itemIndex) => {

        // });
      }
    }
    // _.each(container.components, (component, $index) => {

    // });
  }
  return res;
};

export const filterComponents = (components, filter) => {
  return _.filter(components, component => {
    // if (component.items) {
    //   _.each(component.items, item => {
    //     item.components = filterComponents(item.components, filter);
    //   });
    // }
    if (component.items) {
      component.items = filterComponents(component.items, filter);
    }
    if (component.components) {
      component.components = filterComponents(component.components, filter);
    }
    return filter(component);
  });
};
